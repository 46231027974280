<template>
  <footer class="footer py-4 mt-auto">
    <div class="container-lg px-4">
      <div class="row">
        <div class="col-lg-5 mb-3">
          <p>
            <img src="../assets/img/logo/logo_white.png" style="margin-left: -10px; max-height: 50px" />
          </p>
          <ul class="list-unstyled small text-light">
            <li class="mb-2">电话: 021-5071 9392</li>
            <li class="mb-2">邮箱: sales@ft.tech</li>
            <li class="mb-2">上海：徐汇区虹梅路1801号 凯科国际大厦A区28层</li>
            <li class="mb-2">
              上海：浦东新区世纪大道1196号 世纪汇办公楼T2座17层
            </li>
            <li class="mb-2">北京：东城区青龙胡同1号 歌华大厦A座13层</li>
            <li class="mb-2">深圳：南山区粤海街道 微软科通大厦21B</li>
            <li class="mb-2">成都：武侯区天府二街萃华路89号 成都国际科技节能大厦37层</li>
            <li class="mb-2">徐州：新城区秦郡路金融集聚区I号楼 云龙金融大厦15A层</li>
            <li class="mb-2">
              新加坡：3 Phillip Street Royal Group Building Singapore 048693
            </li>
          </ul>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <h5 class="text-light mt-3">关于我们</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <router-link to="/about/company">公司简介</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/culture">企业文化</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/team">核心团队</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/news">新闻动态</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/livestream-poster">公司活动</router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <h5 class="text-light mt-3">产品服务</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <router-link to="/product/algorithm">非凸智能算法</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/product/t0">非凸T0算法</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/product/platform">非凸智能交易终端</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/product/bus">非凸升级服务组件</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/business">商务合作</router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <h5 class="text-light mt-3">招贤纳士</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <router-link to="/join">加入我们</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/social">社会招聘</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/about/campus">校园招聘</router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <div class="py-2 mt-3 text-center">
            <img class="w-50" src="../assets/img/wechat.png" />
            <p class="text-light mt-2">公众号</p>
          </div>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <div class="py-2 mt-3 text-center">
            <img class="w-50" src="../assets/img/抖音.png" />
            <p class="text-light mt-2">抖音号</p>
          </div>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <div class="py-2 mt-3 text-center">
            <img class="w-50" src="../assets/img/商务合作.jpg" />
            <p class="text-light mt-2">商务合作</p>
          </div>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <div class="py-2 mt-3 text-center">
            <img class="w-50" src="../assets/img/品牌合作.jpg" />
            <p class="text-light mt-2">品牌合作</p>
          </div>
        </div>
        <div class="col-4 col-lg-2 mb-3">
          <div class="py-2 mt-3 text-center">
            <img class="w-50" src="../assets/img/招聘求职.jpg" />
            <p class="text-light mt-2">招聘求职</p>
          </div>
        </div>
      </div>
      <div class="row gx-5 mb-2">
        <div class="d-flex">
          <div class="text-light align-self-end small">关注我们:</div>
          <div class="btn-group gx-5" role="group">
            <a target="_blank" rel="external" class="text-decoration-none px-2" role="button"
              href="https://maimai.cn/company?webcid=17zr73nbo&self=1">
              <img class="icon" src="../assets/img/socialmedia/maimai_icon.png" alt="maimai" />
            </a>
            <a target="_blank" rel="external" class="text-decoration-none px-2" role="button"
              href="https://weibo.com/u/7724045039">
              <img class="icon" src="../assets/img/socialmedia/weibo_icon.jpg" alt="weibo" />
            </a>
            <a target="_blank" rel="external" class="text-decoration-none px-2" role="button"
              href="https://www.zhihu.com/people/fei-tu-ke-ji">
              <img class="icon" src="../assets/img/socialmedia/zhihu_icon.jpg" alt="zhihu" />
            </a>
            <a target="_blank" rel="external" class="text-decoration-none px-2" role="button"
              href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAE09HIw_UCgpQYl9SGU9MgxbHunCSJex9Cx4sjFhqbIr7Msm_4hB1fosjFog98BV7/?source=feed">
              <img class="icon" src="../assets/img/socialmedia/toutiao_icon.png" alt="toutiao" />
            </a>
            <a target="_blank" rel="external" class="text-decoration-none px-2" role="button"
              href="https://space.bilibili.com/1252977794">
              <img class="icon" src="../assets/img/socialmedia/bilibili_icon.jpg" alt="bilibili" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr-light" />
    <div class="container-lg px-4">
      <div class="row">
        <div class="col-12">
          <div class="small m-0 text-white text-center">
            &copy; 2018-{{ currentYear }} 上海非凸智能科技有限公司 <br />
            <div>
              <div style="display:flex; justify-content: center" class="small">
                <a target="_blank"
                  href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020220614134308000009118975-SAIC_SHOW_310000-2c984b4289cda6970189f764d70c4012484&signData=MEQCIDRekHW8I777AoDEGIgo7MRwcUPH67uoHso/u28f26MCAiAvaUsMZsxkw4vD+Fx4DswpBnv3uk2KJ8qaccPPphCvfg=="
                  style="
                    display: inline-block;
                    text-decoration: none;
                    height: 20px;
                    line-height: 20px;
                  ">
                  <img src="../assets/img/营业执照.png" style="float: left" width="23" />
                </a>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009929"
                  style="
                    display: inline-block;
                    text-decoration: none;
                    height: 20px;
                    line-height: 20px;
                  "><img src="../assets/img/备案图标.png" style="float: left" />
                  <p style="
                      float: left;
                      height: 20px;
                      line-height: 20px;
                      margin: 0px 15px 0px 5px;
                    ">
                    沪公网安备 31010402009929号
                  </p>
                </a>

                <a class="small" href="https://beian.miit.gov.cn/" target="_blank" style="
                      float: left;
                      height: 20px;
                      line-height: 20px;
                      margin: 0px 15px 0px 5px;
                    ">
                  沪ICP备2021021911号-1</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import preval from 'babel-plugin-preval/macro'

export default {
  name: "Footer",
  data() {
    const currentYear = preval`module.exports = new Date().getFullYear()`
    return {
      currentYear
    }
  }
};


</script>

<style lang="scss" scoped>
footer {
  // background: $--ft-linear-gradient;
  background-color: #34394b;
}

footer a {
  color: $--ft-light-gray;
  text-decoration: none;
}

footer a:hover {
  color: $--ft-white;
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
}
</style>
