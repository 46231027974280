<template>
  <Menubar :model="items" class="ft-navbar">
    <template #start>
      <router-link to="/"><img src="../assets/img/logo/logo.svg" class="logo" height="45" /></router-link>
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  name: "Header",
  components: {
    Menubar,
  },
  mounted() {
    const nav = document.querySelector(".ft-navbar");
    let lastTop = window.pageYOffset || document.documentElement.scrollTop;

    document.addEventListener("scroll", function () {
      /* console.log(window.pageYOffset || document.documentElement.scrollTop); */
      if ((window.pageYOffset || document.documentElement.scrollTop) >= 100) {
        /* if(document.documentElement.scrollTop >= 100){ */
        // scrolled to the top
        nav.classList.remove("header-top");
        const st = document.documentElement.scrollTop || window.pageYOffset;
        if (st <= lastTop) {
          // scroll up
          nav.style.top = "0px";
          nav.classList.add("bg-blue");
        } else {
          // scroll down
          nav.style.top = "-70px";
          nav.classList.remove("bg-blue");
        }
        lastTop = st;
      } else {
        nav.classList.add("header-top");
      }
    });

  },
  data() {
    return {
      items: [
        {
          label: '关于我们',
          items: [
            {
              label: '公司简介',
              to: "/about/company"
            },
            {
              label: '企业文化',
              to: "/about/culture"
            },
            {
              label: '核心团队',
              to: "/about/team"
            },
            {
              label: '新闻动态',
              to: "/about/news"
            },
            {
              label: '公司活动',
              to: "/about/livestream-poster"
            },
          ]
        },
        {
          label: '产品服务',
          items: [
            {
              label: '非凸基础服务组件',
              items: [
                {
                  label: '智能算法',
                  to: "/product/algorithm"
                },
                {
                  label: 'T0 算法',
                  to: "/product/t0"
                },
              ]
            },
            {
              label: '非凸智能交易终端',
              to: "/product/platform"
            },
            {
              label: '非凸升级服务组件',
              to: "/product/bus"
            },
          ]
        },
        {
          label: '招贤纳士',
          items: [
            {
              label: '加入我们',
              to: "/join"
            },
            {
              label: '社会招聘',
              to: "/about/social"
            },
            {
              label: '校园招聘',
              to: "/about/campus"
            },
          ]
        },
        {
          label: '商务合作',
          to: "/business"
        },
      ]
    }
  }
};
</script>

<style lang="scss">
.ft-navbar {
  &.p-menubar {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    backdrop-filter: blur(24px);
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 2000;
    transition: all 0.3s linear;
    border: none;
  }


  @media screen and (max-width: map-get($container-max-widths, lg)) {
    &.p-menubar {
      padding: 0 1rem;
    }
  }

  &.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: none;
  }
  &.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background-color: transparent !important;
  }

  .p-menuitem-link {
    padding: 8px;

    .p-menuitem-text {
      font-size: 18px !important;
    }
  }

  .logo {
    height: 45px;
  }
}
</style>
